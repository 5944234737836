import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

require("es6-promise").polyfill()

const smartfeedNotFound = ({ id = 1 }) => {
  return (
    <Layout>
      <SEO title="404: Not found" />
      <div className="notfound">
        <section className="app-content">
          <div className="app-content-content">
            <div className="container">
              <h1>Sorry, we couldn't find that page.</h1>
              <a href="/" className="btn btn-primary button-primary-hero">
                Go back
              </a>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default smartfeedNotFound
