import React, { useState, useEffect } from "react"

import { Link } from "gatsby"

import { Map, TileLayer, Marker } from "react-leaflet"

import Layout from "../components/layout"
import SEO from "../components/seo"

import fetchJsonp from "fetch-jsonp"

import "url-search-params-polyfill"

import "../components/leaflet.css"

var isBase64 = require("is-base64")

require("es6-promise").polyfill()

var Scroll = require("react-scroll")
var Element = Scroll.Element
var scroller = Scroll.scroller

function calculateSalary(
  Currency,
  SalaryFrom,
  SalaryTo,
  SalaryType,
  SalaryPeriod
) {
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }

  let NewSalary
  let NewSalaryFrom
  let NewSalaryTo

  let currencyFormatted = Currency

  if (Currency === "&pound;") {
    currencyFormatted = "£"
  }

  if (SalaryTo === 0) {
    NewSalary = SalaryType
  } else if (SalaryFrom === SalaryTo) {
    if (SalaryFrom >= 1000) {
      // Remove decimals
      NewSalaryFrom = currencyFormatted + numberWithCommas(SalaryFrom.toFixed())
    } else {
      // Add two decimals
      NewSalaryFrom =
        currencyFormatted + numberWithCommas(SalaryFrom.toFixed(2))
    }
    NewSalary = NewSalaryFrom + " " + SalaryPeriod
  } else {
    if (SalaryFrom >= 1000) {
      NewSalaryFrom = currencyFormatted + numberWithCommas(SalaryFrom.toFixed())
    } else {
      NewSalaryFrom =
        currencyFormatted + numberWithCommas(SalaryFrom.toFixed(2))
    }
    if (SalaryTo >= 1000) {
      NewSalaryTo = currencyFormatted + numberWithCommas(SalaryTo.toFixed())
    } else {
      NewSalaryTo = currencyFormatted + numberWithCommas(SalaryTo.toFixed(2))
    }

    NewSalary = NewSalaryFrom + " - " + NewSalaryTo + " " + SalaryPeriod
  }
  return NewSalary
}

function CheckJobHasNotExpired(JSONDate) {
  if (JSONDate) {
    let expDate = new Date(parseInt(JSONDate.substr(6), 10))
    let now = new Date()

    if (expDate > now) {
      return true
    }

    return false
  } else {
    return false
  }
}

function FormatDateTime(JSONDate) {
  if (JSONDate) {
    // Set default values to avoid function breaking
    var expDate = new Date(parseInt(JSONDate.substr(6)))
    var dateFormatOptions = new Object({})
    dateFormatOptions.weekday = "long"
    dateFormatOptions.year = "numeric"
    dateFormatOptions.month = "short"
    dateFormatOptions.day = "numeric"
    return expDate.toLocaleDateString(undefined, dateFormatOptions)
  } else {
    return JSONDate
  }
}

function generateGoogleJobsSchema(vacancy) {
  return {
    "@context": "http://schema.org",
    "@type": "JobPosting",
    baseSalary: calculateSalary(
      vacancy.Currency,
      vacancy.SalaryFrom,
      vacancy.SalaryTo,
      vacancy.SalaryType,
      vacancy.SalaryPeriod
    ),
    jobBenefits: vacancy.Benefits,
    datePosted: FormatDateTime(vacancy.PostingDate),
    validThrough: FormatDateTime(vacancy.ExpiryDate),
    description: vacancy.Description,
    employmentType: vacancy.JobTypeTime,
    industry: vacancy.Industry,
    jobLocation: {
      "@type": "Place",
      geo: {
        longitude: vacancy.Longitude,
        latitude: vacancy.Latitude,
        "@type": "GeoCoordinates",
      },
      address: {
        "@type": "PostalAddress",
        addressCountry: vacancy.Country,
        addressLocality: vacancy.Location,
        addressRegion: vacancy.Region,
        postalCode: vacancy.PostCode,
      },
    },
    occupationalCategory: vacancy.JobCategory,
    salaryCurrency: vacancy.Currency === "&pound;" ? "GBP" : "EUR",
    hiringOrganization: {
      "@type": "Organization",
      name: vacancy.Company,
      logo: vacancy.CompanyLogoPath,
    },
    title: vacancy.JobTitle,
    workHours: vacancy.JobTypeTime,
  }
}

const listenForIFrameHeight = function listenForIFrameHeight() {
  if (typeof window !== "undefined") {
    window.addEventListener(
      "message",
      function (event) {
        let careersPageiFrame = null

        if (document.getElementById("vf_iFrame_application_form")) {
          careersPageiFrame = document.getElementById(
            "vf_iFrame_application_form"
          )
        }

        if (
          careersPageiFrame !== null &&
          careersPageiFrame !== undefined &&
          careersPageiFrame !== "undefined"
        ) {
          let careersPageEvent = event.data[0]
          let careersPageHeight = event.data[1]

          switch (careersPageEvent) {
            case "setCareersPageHeight":
              let careersPageHeightExtra = careersPageHeight + 50

              console.log("cp height")
              console.log(careersPageHeightExtra)

              careersPageiFrame.height = careersPageHeightExtra
              break
          }
        }
      },
      false
    )
  }
}

const SmartfeedIndividual = ({ id = 1, vacancyTitlePath = false }) => {
  const [vacancy, setVacancy] = useState([])
  const [showApplication, setshowApplication] = useState(false)
  const [vacancyTitle, setVacancyTitle] = useState("Loading vacancy....")
  const [loading, setLoading] = useState(false)
  const [loadingText, setLoadingText] = useState("")

  useEffect(() => {
    listenForIFrameHeight()
    setshowApplication(false)

    setTimeout(function () {
      setLoadingText(
        <span>
          If this is taking too long, please{" "}
          <Link to="/">go back to the search page</Link> and re-select a
          vacancy.
        </span>
      )
    }, 3000)

    const timeOut = setTimeout(() => {
      if (isBase64(id) && vacancyTitlePath) {
        fetchVacancy(new Buffer(id, "base64").toString())
      } else {
        fetchVacancy(id)
      }
    }, 25)
    return () => clearTimeout(timeOut)
  }, [id])

  function vfScrollToElement(element, to, duration) {
    if (duration <= 0) return
    var difference = to - element.scrollTop
    var perTick = (difference / duration) * 10

    setTimeout(function () {
      element.scrollTop = element.scrollTop + perTick
      if (element.scrollTop === to) return
      window.scrollTo(element, to, duration - 10)
    }, 10)
  }

  function animate(elem, style, unit, from, to, time, prop) {
    if (!elem) {
      return
    }
    var start = new Date().getTime(),
      timer = setInterval(function () {
        var step = Math.min(1, (new Date().getTime() - start) / time)
        if (prop) {
          elem[style] = from + step * (to - from) + unit
        } else {
          elem.style[style] = from + step * (to - from) + unit
        }
        if (step === 1) {
          clearInterval(timer)
        }
      }, 25)
    if (prop) {
      elem[style] = from + unit
    } else {
      elem.style[style] = from + unit
    }
  }

  function activateApplicationForm(setting) {
    setshowApplication(setting)

    scroller.scrollTo("ApplicationScrollTarget", {
      duration: 500,
      delay: 0,
      smooth: true,
      offset: -150,
    })
  }

  const fetchVacancy = async (id) => {
    setLoading(true)
    try {
      fetchJsonp("https://sf.vacancy-filler.co.uk/CareerPage/GetItem?id=" + id)
        .then(function (response) {
          return response.json()
        })
        .then(function (json) {
          const responseVacancy = json
          const resultVacancy = responseVacancy
          setLoading(false)
          setVacancy(resultVacancy)
          setVacancyTitle(resultVacancy.JobTitle)
          return resultVacancy
        })
        .catch(function (ex) {
          return fetchJsonp(
            `https://sf.vacancy-filler.co.uk/CareerPage/GetItem?id=${new Buffer(
              id,
              "base64"
            ).toString()}`
          )
            .then(function (response) {
              return response.json()
            })
            .then(function (json) {
              const responseVacancy = json
              const resultVacancy = responseVacancy
              setLoading(false)
              setVacancy(resultVacancy)
              setVacancyTitle(resultVacancy.JobTitle)
              return resultVacancy
            })
            .catch(function (ex) {
              setLoading(false)
              console.log(ex, "error")
            })
        })

      /*  
     JSON method, sadly doesn't work with SF
     const fetchingVacancy = await fetch(
        `https://sf.vacancy-filler.co.uk/CareerPage/GetItem?callback=gatsby&id=${id}`
      )
      const responseVacancy = await fetchingVacancy.json()
      const resultVacancy = await responseVacancy
      setLoading(false)
      setVacancy(resultVacancy)
      return resultVacancy 
      */
    } catch (error) {
      setLoading(false)
      console.log(error.message, "error")
    }
  }

  return (
    <Layout>
      <SEO title={loading ? "Loading vacancy..." : vacancyTitle} />
      <div className="main-container main-container--single">
        {!vacancy ||
          vacancy == [] ||
          (!vacancy.Description && (
            <div className="page__header-container page__header-container--vacancies-landing page__header-container--vacancies-landing-loading">
              <div className="container-fluid single-loading">
                <div
                  className="row justify-content-center page__header page__header--landing vacancies-landing__header page__header--vacancies-landing"
                  id="listing-top-single-loading"
                >
                  <div className="col-12 col-lg-8">
                    <>
                      <h1>Loading vacancy...</h1>
                      <p className="small-padding">
                        Please wait while we load the vacancy that you
                        requested.
                      </p>
                      {loadingText && <p>{loadingText}</p>}
                    </>
                  </div>
                </div>
              </div>
            </div>
          ))}

        {vacancy && vacancy != [] && vacancy.Description && (
          <div>
            <div id="ApplicationScrollTarget"></div>
            {!showApplication ? (
              <>
                {vacancy.PostingDate &&
                  vacancy.ExpiryDate &&
                  CheckJobHasNotExpired(vacancy.ExpiryDate) && (
                    <script
                      type="application/ld+json"
                      dangerouslySetInnerHTML={{
                        __html: JSON.stringify(
                          generateGoogleJobsSchema(vacancy)
                        ),
                      }}
                    />
                  )}

                <div className="page__header-container page__header-container--vacancies-landing page__header-container--vacancies-landing-single">
                  <div className="container">
                    <div
                      className="row justify-content-center page__header page__header--landing vacancies-landing__header page__header--vacancies-landing page__header--vacancies-landing-single"
                      id="listing-top"
                    >
                      <div className="col-12">
                        <>
                          {!vacancy ||
                            vacancy == [] ||
                            (!vacancy.Description && (
                              <h1 className="vf-jobtitle">
                                Loading vacancy...
                              </h1>
                            ))}
                          {vacancy && vacancy != [] && vacancy.Description && (
                            <>
                              <h1 className="vf-jobtitle">{vacancyTitle}</h1>
                              <div className="job-title-meta">
                                <span>
                                  {"(Reference: " +
                                    vacancy.AdvertReference +
                                    ")"}
                                </span>
                              </div>
                            </>
                          )}
                        </>
                      </div>
                      <div className="col-12">
                        <>
                          {vacancy && vacancy != [] && vacancy.Description && (
                            <div className="vf-details row">
                              <div className="col-12 col-lg-6">
                                <div className="vf-job-info-container">
                                  <div className="vf-job-info-title">
                                    Location:
                                  </div>
                                  <div className="vf-job-info-value vf-Location">
                                    {vacancy.Location}
                                  </div>
                                </div>
                                <div className="vf-job-info-container">
                                  <div className="vf-job-info-title">
                                    Job Category:
                                  </div>
                                  <div className="vf-job-info-value">
                                    <span className="vf-category">
                                      {vacancy.JobCategory}
                                    </span>
                                  </div>
                                </div>
                                <div className="vf-job-info-container">
                                  <div className="vf-job-info-title">
                                    Salary:
                                  </div>
                                  <div className="vf-job-info-value vf-Salary">
                                    {calculateSalary(
                                      vacancy.Currency,
                                      vacancy.SalaryFrom,
                                      vacancy.SalaryTo,
                                      vacancy.SalaryType,
                                      vacancy.SalaryPeriod
                                    )}
                                  </div>
                                </div>
                                <div className="vf-job-info-container">
                                  <div className="vf-job-info-title">
                                    Closing Date:
                                  </div>
                                  <div className="vf-job-info-value vf-ExpiryDate">
                                    {FormatDateTime(vacancy.ExpiryDate)}
                                  </div>
                                </div>
                              </div>
                              <div className="col-12 col-lg-6 vf-attachements-col">
                                <div className="vf-job-info-container">
                                  {vacancy.Attachments &&
                                    vacancy.Attachments != [] &&
                                    vacancy.Attachments.length >= 0 && (
                                      <ul className="vf-Attachments">
                                        {vacancy.Attachments &&
                                          vacancy.Attachments.map(
                                            (attachment, i) => (
                                              <li
                                                key={i}
                                                id={attachment.S3Name}
                                                className="row"
                                              >
                                                <a
                                                  className="btn-filedownload vf-row"
                                                  href={attachment.Location}
                                                  target="_blank"
                                                >
                                                  <span className="details vf-col-xs-8">
                                                    <div className="fal fa-download"></div>
                                                    <span className="filename">
                                                      {"Download " +
                                                        attachment.FileName.substring(
                                                          0,
                                                          19
                                                        )}
                                                      {attachment.FileName
                                                        .length > 19
                                                        ? "..."
                                                        : ""}
                                                    </span>
                                                  </span>
                                                </a>
                                              </li>
                                            )
                                          )}
                                      </ul>
                                    )}
                                </div>
                              </div>
                            </div>
                          )}
                        </>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="vacancies-landing">
                  <div className="container">
                    <div className="row clearfix">
                      <div className="col-md-12 column">
                        <div className="healthspan-spacing">
                          <div className="row vacancyDetailsWrap">
                            <div className="col-12 col-lg-12 job-description">  
                              <div className="vf-JobDescription">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: vacancy.Description,
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <section>
                  <div>
                    <div className="container">
                      <div className="row clearfix">
                        <div className="col-md-12 column">
                          {" "}
                          <div>
                            <div className="healthspan-spacing">
                              <article tabIndex="-1">
                                <div className="ctacard ctacard-application">
                                  <div className="ctacard__body d-flex justify-content-center">
                                    <div className="col-md-10 col-lg-6 text-center">
                                      <h4 className="ctacard__body__title">
                                        Apply for this role
                                      </h4>
                                      <p className="ctacard__body__summary">
                                        Click the button below to apply for this
                                        vacancy with our online application
                                        form.
                                      </p>

                                      <a
                                        target="_blank"
                                        href={
                                          vacancy.Link +
                                          "&action=ApplyNow&iframe=False"
                                        }
                                        className="btn--action"
                                      >
                                        Apply now
                                      </a>
                                      <div className="vf-JobActions">
                                        <div id="share-icons">
                                          <div className="vf-SocialButtons">
                                            <span>
                                              Share this role instead:
                                            </span>
                                            <a
                                              data-sharelinkend=""
                                              href={
                                                "https://www.facebook.com/sharer/sharer.php?u=" +
                                                "http://healthspancareers.co.uk//vacancies/vacancy/" +
                                                encodeURIComponent(vacancy.Id) +
                                                "%26ExtraData=%26utm_source=social%26utm_campaign=sharing_buttons"
                                              }
                                              target="_blank"
                                              rel="nofollow"
                                              className="vf-facebook-sharing-button vf-shareButton vf-activate-sharing-button"
                                            >
                                              <img
                                                src="https://cdn1.accropress.com/Woodland%20Trust/images/facebook-dark.svg"
                                                alt="facebook"
                                              />
                                            </a>
                                            <a
                                              href={
                                                "https://twitter.com/intent/tweet?text=" +
                                                vacancyTitle +
                                                " - " +
                                                "http://healthspancareers.co.uk//vacancies/vacancy/" +
                                                encodeURIComponent(vacancy.Id) +
                                                "%26ExtraData=%26utm_source=social%26utm_campaign=sharing_buttons"
                                              }
                                              target="_blank"
                                              rel="nofollow"
                                              className=" vf-twitter-sharing-button vf-shareButton vf-activate-sharing-button"
                                            >
                                              <img
                                                src="https://cdn1.accropress.com/Woodland%20Trust/images/twitter-dark.svg"
                                                alt="Twitter"
                                              />
                                            </a>
                                            <a
                                              href={
                                                "https://www.linkedin.com/shareArticle?mini=true&url=" +
                                                "http://healthspancareers.co.uk//vacancies/vacancy/" +
                                                encodeURIComponent(vacancy.Id) +
                                                "%26ExtraData=%26utm_source=social%26utm_campaign=sharing_buttons" +
                                                "&title=" +
                                                vacancyTitle +
                                                "&summary=&source="
                                              }
                                              target="_blank"
                                              rel="nofollow"
                                              className=" vf-linkedin-sharing-button vf-shareButton vf-activate-sharing-button"
                                            >
                                              <img
                                                src="https://cdn1.accropress.com/Woodland%20Trust/images/linkedin-icon.svg"
                                                alt="LinkedIn"
                                              />
                                            </a>
                                            <a
                                              href={
                                                "mailto:?&subject=Check out this vacany&body=Check out this " +
                                                vacancy.JobTitle +
                                                " vacancy " +
                                                " - http://healthspancareers.co.uk//vacancies/vacancy/" +
                                                encodeURIComponent(vacancy.Id) +
                                                "%26ExtraData=%26utm_source=social%26utm_campaign=sharing_buttons"
                                              }
                                              target="_blank"
                                              rel="nofollow"
                                              className=" vf-email-sharing-button vf-shareButton vf-activate-sharing-button"
                                            >
                                              <img
                                                src="https://cdn1.accropress.com/Woodland%20Trust/images/newsletter-icon.svg"
                                                alt="Email"
                                              />
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </article>
                            </div>{" "}
                          </div>
                        </div>{" "}
                      </div>
                    </div>
                  </div>
                </section>
              </>
            ) : (
              <div className="vacancy-application-wrapper">
                <div className="vacancy-application-topbar vacancy-application-topbar---standard-app">
                  <div className="container">
                    <div className="row">
                      <div className="col-12 col-lg-6 job-title-app">
                        <span>{vacancyTitle + " application"}</span>
                      </div>
                      <div className="col-12 col-lg-6 back-to-description">
                        <a
                          className="btn--action"
                          onClick={() => {
                            activateApplicationForm(false)
                          }}
                        >
                          Go back to the description
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="vf_iFrame_wrapper">
                  <div className="container">
                    <iframe
                      id="vf_iFrame_application_form"
                      src={
                        vacancy.Link + "&action=ApplyNow&HideCareersLink=true"
                      }
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </Layout>
  )
}

export default SmartfeedIndividual
