import React, { useState, useEffect } from "react"

import fetchJsonp from "fetch-jsonp"
import SmartFeedJobAlerts from "./SmartFeedJobAlerts"

import { Link } from "gatsby"

import "url-search-params-polyfill"

require("es6-promise").polyfill()

let prevCount = 0
let currentCount = 0
let keywordTime = null

const setVacancyLoadingBackup = function setVacancyLoadingBackup(
  loading,
  vacancyLoading
) {
  if (loading) {
    vacancyLoading = true
    return true
  }

  vacancyLoading = false
  return false
}

const SmartFeedFiltersComponent = ({
  vacancyLoading = true,
  apiKey = "",
  gdprKey = "",
  group = false,
  CompanyGroupName = "",
  VacancyPageBaseURL = "",
  ReturnToSearchURL = "",
  contentDataData = [],
  setVacancyLoading = setVacancyLoadingBackup,
}) => {
  const id = 1
  const [vacancy, setVacancy] = useState([])
  const [vacancyTitle, setVacancyTitle] = useState("Loading vacancies...")
  const [isIinitialLoad, setIsIinitialLoad] = useState(true)
  const [initialLoading, setInitialLoading] = useState(true)

  // Filter States
  const [locationFilter, setLocationFilter] = useState([])
  const [regionFilter, setRegionFilter] = useState([])
  const [categoryFilter, setCategoryFilter] = useState([])
  const [industryFilter, setIndustryFilter] = useState([])
  const [postingTypeFilter, setPostingTypeFilter] = useState([])
  const [jobTypeFilter, setJobTypeFilter] = useState([])
  const [keywordFilter, setKeywordFilter] = useState([])
  const [autoCompleteFilter, setAutoCompleteFilter] = useState([])
  const [autocompleteListHide, setAutocompleteListHide] = useState(true)
  const [filterState, setFilterState] = useState({})
  const [vacsCount, setVacsCount] = useState(0)
  const [loading, setLoading] = useState(true)
  const [loadMoreText, setLoadMoreText] = useState(false)

  // Button filters
  const [careersNavState, setCareersNavState] = useState("careers")

  const addActiveClassnameToNav = function addActiveClassnameToNav(stateName) {
    if (stateName === careersNavState) {
      return "VF_Nav_Item_Active"
    }

    return ""
  }

  let groupOrIdParam = "id"

  if (group) {
    groupOrIdParam = "group"
  }

  function removeDuplicates(array) {
    return array.filter((a, b) => array.indexOf(a) === b)
  }

  const fetchFilters = async (name, setState, label) => {
    try {
      fetchJsonp(
        "https://sf.vacancy-filler.co.uk/CareerPage/" +
          name +
          "?" +
          groupOrIdParam +
          "=" +
          apiKey
      )
        .then(function (response) {
          return response.json()
        })
        .then(function (jsonList) {
          // let temp = ["All " + label]
          let temp = ["- Filter by " + label + " -"]

          setState(temp.concat(removeDuplicates(jsonList)))

          setVacancyLoading(false)
        })
        .catch(function (ex) {
          setLoading(false)
          setVacancyLoading(false)
          console.log(ex, "error")
        })
    } catch (error) {
      setLoading(false)
      setVacancyLoading(false)
      console.log(error.message, "error")
    }
  }

  const fetchVacancies = async (
    state,
    name,
    e,
    loadMore,
    postingTypeSelection
  ) => {
    let postingTypeSelectionValue = careersNavState

    if (careersNavState !== postingTypeSelection && postingTypeSelection) {
      postingTypeSelectionValue = postingTypeSelection
    }

    // State of filters, Name of searched filter, event, load more boolean
    setLoadMoreText("Loading.....")

    if (name) {
      prevCount = 0
      currentCount = 0
    }

    let temp = state

    if (name) {
      if (e) {
        if (e.target && e.target.value.includes(name)) {
          e.target.value = ""
        }
      }

      if (name === "autocomplete") {
        temp.latitude = e.lat
        temp.longitude = e.long
        temp.distance = e.distance
        temp.unit = "Miles"
      } else if (name === "location") {
        if (e.target.value.includes("All ")) {
          delete temp.location
        } else {
          temp.location = e.target.value
        }
      } else if (name === "keywords") {
        temp.keywords = e.target.value
      } else if (name === "region") {
        if (e.target.value.includes("All ")) {
          delete temp.region
        } else {
          temp.region = e.target.value
        }
      } else if (name === "category") {
        if (e.target.value.includes("All ")) {
          delete temp.category
        } else {
          temp.category = e.target.value
        }
      } else if (name === "industry") {
        if (e.target.value.includes("All ")) {
          delete temp.industrysector
        } else {
          temp.industrysector = e.target.value
        }
      } else if (name === "postingType") {
        if (e.target.value.includes("All ")) {
          delete temp.postingtype
        } else {
          temp.postingtype = e.target.value
        }
      } else if (name === "jobtype") {
        if (e.target.value.includes("All ")) {
          delete temp.jobtype
        } else {
          temp.jobtype = e.target.value
        }
      } else if (name === "jobtime") {
        if (e.target.value.includes("All ")) {
          delete temp.jobtime
        } else {
          temp.jobtime = e.target.value
        }
      } else if (name === "country") {
        if (e.target.value.includes("All ")) {
          delete temp.country
        } else {
          temp.country = e.target.value
        }
      } else if (name === "companySearch") {
        if (e.target.value.includes("All ")) {
          delete temp.CompanySearch
        } else {
          temp.CompanySearch = e.target.value
        }
      } else if (name === "reset") {
        delete temp.CompanySearch
        delete temp.country
        delete temp.jobtime
        delete temp.jobtype
        delete temp.postingtype
        delete temp.industrysector
        delete temp.category
        delete temp.region
        delete temp.latitude
        delete temp.longitude
        delete temp.distance
        delete temp.unit

        if (document && document.getElementById("Keywords")) {
          document.getElementById("Keywords").value = ""
        }
      }

      setFilterState(temp)
    }

    function getAdvertData(jobs) {
      return Promise.all(
        jobs.slice(prevCount, currentCount).map(async (id) => {
          return fetchJsonp(
            "https://sf.vacancy-filler.co.uk/CareerPage/GetItem?id=" + id
          ).then(function (response) {
            return response.json()
          })
        })
      )
    }

    if (!loadMore) {
      prevCount = 0
      currentCount = 0
      setLoading(true)
    }

    try {
      fetchJsonp(
        "https://sf.vacancy-filler.co.uk/CareerPage/GetResultList?id=" +
          apiKey +
          "&" +
          "sortby=postingdate" +
          "&" +
          "sortdir=desc" +
          "&" +
          "postingtype=" +
          postingTypeSelectionValue +
          "&" +
          buildQuery(temp)
      )
        .then(function (response) {
          return response.json()
        })
        .then(async function (jsonList) {
          prevCount = currentCount
          currentCount = currentCount + 10

          await getAdvertData(jsonList).then(function (data) {
            if (loadMore) {
              setVacancy(vacancy.concat(data))
            } else {
              setVacancy(data)
            }

            if (jsonList.length === 0) {
              setVacancyTitle(
                "We couldn't find any vacancies matching your search"
              )
            } else if (jsonList.length === 1) {
              setVacancyTitle("We have " + jsonList.length + " vacancy")
            } else {
              setVacancyTitle("We have " + jsonList.length + " vacancies")
            }

            setInitialLoading(false)

            setLoading(false)
            setVacancyLoading(false)

            setLoadMoreText("Load More")
            setVacsCount(jsonList.length)
          })
        })
    } catch (error) {
      setLoading(false)
      setVacancyLoading(false)
      console.log(error.message, "error")
    }
  }

  const chooseGeoLocation = function chooseGeoLocation(lat, long, distance) {
    setAutocompleteListHide(true)
    fetchVacancies(
      filterState,
      "autocomplete",
      { lat: lat, long: long, distance: distance },
      false
    )
  }

  const renderFilter = function renderFilter(data, label, stateName) {
    if (label === "Keyword Search") {
      return (
        <div className="vf-filter">
          <label aria-label={label} htmlFor={"select-" + label}>
            {label}:{" "}
          </label>
          <input
            defaultValue={filterState[stateName]}
            onChange={(e) => {
              e.persist()
              keywordSearchChange(filterState, stateName, e, false)
            }}
            className="VF-form-control onchange-search form-control"
            id={"select-" + label}
            name={label}
            placeholder="Enter keywords"
            type="text"
            aria-labelledby="keywordSearchLabel"
            aria-describedby="Enter keywords"
          />
        </div>
      )
    } else if (label === "Search Location") {
      return (
        <div className="vf-filter">
          <label aria-label={label} htmlFor={"select-" + label}>
            {label}:{" "}
          </label>
          <div className="vf-filter-loc-complete-wrap">
            <input
              value={filterState[stateName]}
              onChange={(e) =>
                autocompleteSearchChange(filterState, label, e, false)
              }
              className="VF-form-control onchange-search form-control"
              id="Keywords"
              name="Keywords"
              placeholder="Enter postcode, town or city"
              type="text"
              aria-labelledby="keywordSearchLabel"
              aria-describedby="Enter keywords"
            />
            {!autocompleteListHide &&
              data &&
              Array.isArray(data) &&
              data.length > 0 && (
                <div className="vf-filter-loc-complete-list">
                  {data.map((loc, i) => (
                    <div
                      key={i}
                      onClick={() =>
                        chooseGeoLocation(loc.latitude, loc.longitude, 10)
                      }
                    >
                      {loc.displayName}
                    </div>
                  ))}
                </div>
              )}
          </div>
        </div>
      )
    } else {
      return (
        <div className="vf-filter">
          <label aria-label={label} htmlFor={"select-" + stateName}>
            {label}:{" "}
          </label>
          <select
            className="form-control"
            value={filterState[stateName] || data[0]}
            onChange={(e) => fetchVacancies(filterState, stateName, e, false)}
            name={"select-" + stateName}
            id={"select-" + stateName}
          >
            {data.map((item, i) => (
              <option key={i} value={item}>
                {item}
              </option>
            ))}
          </select>
        </div>
      )
    }
  }

  const clickCurrentVacancies = function clickCurrentVacancies() {
    setCareersNavState("careers")
    fetchVacancies(filterState, undefined, undefined, false, "careers")
  }

  const clickRegisterVacancies = function clickRegisterVacancies() {
    setCareersNavState("register")
    fetchVacancies(filterState, undefined, undefined, false, "register")
  }

  const clickAlertsVacancies = function clickAlertsVacancies() {
    setCareersNavState("alerts")
  }

  const loadMore = function loadMore() {
    fetchVacancies(filterState, undefined, undefined, true)
  }

  const resetVacs = function resetVacs() {
    fetchVacancies({}, "reset", undefined, false)
  }

  const searchVacs = function searchVacs() {
    fetchVacancies({}, "search", undefined, false)
  }

  const autocompleteSearchChange = function autocompleteSearchChange(
    state,
    name,
    e,
    loadMore
  ) {
    setAutocompleteListHide(false)

    let val = e.currentTarget.value

    if (e.currentTarget.value.length > 3) {
      // Do api call to get key
      fetchJsonp(
        "https://sf.vacancy-filler.co.uk/CareerPage/GetLocationAPIKey?id=" +
          apiKey
      )
        .then(function (response) {
          return response.json()
        })
        .then(function (geoKey) {
          // Do api call to get locs
          fetch(
            "https://geocoding.vacancy-filler.co.uk/api/V2/exact/?apikey=" +
              geoKey.key +
              "&search=" +
              encodeURIComponent(val)
          )
            .then((res) => res.json())
            .then((result) => {
              setAutoCompleteFilter(result.locationResults)
            })
        })
    }
  }

  const keywordSearchChange = function keywordSearchChange(
    state,
    name,
    e,
    loadMore
  ) {
    clearTimeout(keywordTime)

    keywordTime = setTimeout(() => {
      fetchVacancies(state, name, e, loadMore)
    }, 500)
  }

  const getSummary = function getSummary(summary, description) {
    if (summary) {
      return summary
    } else {
      let alteredDescription = description.replace(/<[^>]*>?/gm, "")
      let tempSummary = alteredDescription.substring(0, 397)
      if (tempSummary.length === 397) {
        tempSummary += "..."
      }
      return tempSummary
    }
  }

  const calculateSalary = function calculateSalary(
    Currency,
    SalaryFrom,
    SalaryTo,
    SalaryType,
    SalaryPeriod
  ) {
    function numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }

    let NewSalary
    let NewSalaryFrom
    let NewSalaryTo

    let currencyFormatted = Currency

    if (Currency === "&pound;") {
      currencyFormatted = "£"
    }

    if (SalaryTo === 0) {
      NewSalary = SalaryType
    } else if (SalaryFrom === SalaryTo) {
      if (SalaryFrom >= 1000) {
        // Remove decimals
        NewSalaryFrom =
          currencyFormatted + numberWithCommas(SalaryFrom.toFixed())
      } else {
        // Add two decimals
        NewSalaryFrom =
          currencyFormatted + numberWithCommas(SalaryFrom.toFixed(2))
      }
      NewSalary = NewSalaryFrom + " " + SalaryPeriod
    } else {
      if (SalaryFrom >= 1000) {
        NewSalaryFrom =
          currencyFormatted + numberWithCommas(SalaryFrom.toFixed())
      } else {
        NewSalaryFrom =
          currencyFormatted + numberWithCommas(SalaryFrom.toFixed(2))
      }
      if (SalaryTo >= 1000) {
        NewSalaryTo = currencyFormatted + numberWithCommas(SalaryTo.toFixed())
      } else {
        NewSalaryTo = currencyFormatted + numberWithCommas(SalaryTo.toFixed(2))
      }

      NewSalary = NewSalaryFrom + " - " + NewSalaryTo + " " + SalaryPeriod
    }
    return NewSalary
  }
  const getImageFromRegionCards = function getImageFromRegionCards(region) {
    let jobCardRegionNoSpaces = region.replace(/[, ]+/g, "-")

    if (jobCardRegionNoSpaces === "Cheddar-Gorge-&-Caves") {
      return "Cheddar-Gorge-and-Caves"
    }

    return jobCardRegionNoSpaces
  }

  const FormatDateTime = function FormatDateTime(JSONDate) {
    // Set default values to avoid function breaking
    var expDate = new Date(parseInt(JSONDate.substr(6)))
    var dateFormatOptions = new Object({})
    dateFormatOptions.weekday = "long"
    dateFormatOptions.year = "numeric"
    dateFormatOptions.month = "short"
    dateFormatOptions.day = "numeric"
    return expDate.toLocaleDateString(undefined, dateFormatOptions)
  }

  const buildQuery = function buildQuery(data) {
    if (typeof data === "string") return data
    let query = []

    for (let key in data) {
      if (data.hasOwnProperty(key)) {
        query.push(
          encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
        )
      }
    }

    return query.join("&")
  }

  const slugify = function slugify(text) {
    return text
      .toString() // Cast to string
      .toLowerCase() // Convert the string to lowercase letters

      .trim() // Remove whitespace from both sides of a string
      .replace(/\s+/g, "-") // Replace spaces with -
      .replace(/[^\w\-]+/g, "") // Remove all non-word chars
      .replace(/\-\-+/g, "-") // Replace multiple - with single -
  }

  useEffect(() => {
    if (isIinitialLoad) {
      setIsIinitialLoad(false)
      if (URLSearchParams) {
        var urlParams = new URLSearchParams(window.location.search)
        var entries = urlParams.entries()
      }
      let temp = {}
      for (let pair of entries) {
        temp[pair[0]] = pair[1]
      }

      setFilterState(temp)

      const timeOut = setTimeout(() => {
        fetchVacancies(temp, undefined, undefined, false)
        if (group) {
          fetchFilters("GetLocationListByGroup", setLocationFilter, "Locations") // Locations
          fetchFilters("GetRegionListByGroup", setLocationFilter, "Regions") // Regions
          fetchFilters(
            "GetCategoryListByGroup",
            setCategoryFilter,
            "Categories"
          ) // Categories
          fetchFilters(
            "GetIndustryListByGroup",
            setIndustryFilter,
            "Industries"
          ) // Industries
          fetchFilters(
            "GetPostingTypesListByGroup",
            setPostingTypeFilter,
            "Posting Types"
          ) // Posting Types
          fetchFilters("GetJobTypeListByGroup", setJobTypeFilter, "Job Types") // Job Types
        } else {
          //fetchFilters("GetLocations", setLocationFilter, "Locations") // Locations
          fetchFilters("GetRegions", setRegionFilter, "Region") // Regions
          fetchFilters("GetCategories", setCategoryFilter, "Categories") // Categories
          fetchFilters("GetkeywordFilter", setKeywordFilter, "Keyword") // Keyword
          //fetchFilters("GetIndustries", setIndustryFilter, "Industries") // Industries
          //fetchFilters("GetPostingTypes", setPostingTypeFilter, "Posting Types") // Posting Types
          fetchFilters("GetJobTypes", setJobTypeFilter, "Job Types") // Job Types
        }
      }, 50)
      return () => clearTimeout(timeOut)
    }
  }, [careersNavState])

  return (
    <div className={vacancyLoading ? "vacancies-landing-loading" : ""}>
      {!vacancyLoading ? (
        <>
          <div className="vacancies-landing">
            <div
              className="banner"
              style={{
                backgroundRepeat: "no-repeat",
                backgroundImage: `url(https://cdn2.accropress.com/content-uploads%2F29cdbd1f-7836-436a-8b16-1b5a14d31ba3%2Fe5b6307e-e6a4-4cb1-834e-fbc333c43631%2F9ok5DsFGSHCCquEywcot_banner_jpg)`,
              }}
            >
              <div className="text-block"></div>

              <div className="container filters-container">
                <h1>Careers at Healthspan</h1>
                <div className="filters">
                  <div className="page-search-input-group w-100 blog__listing__select row">
                    <div className="col-12 col-lg-4">
                      {renderFilter(
                        keywordFilter,
                        "Keyword Search",
                        "keywords"
                      )}
                    </div>
                    <div className="col-12 col-lg-4">
                      {renderFilter(
                        jobTypeFilter,
                        "- Filter by Job Type -",
                        "jobtype"
                      )}
                    </div>
                    <div className="col-12 col-lg-4">
                      {renderFilter(
                        categoryFilter,
                        "- Filter by Category -",
                        "category"
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <section>
            <div>
              <div className="container">
                <div className="row clearfix">
                  <div className="col-12 column job-vacancies-col">
                    <h3 className="vacancies-landing__header__heading">
                      {vacancyTitle}
                    </h3>
                    <div className="healthspan">
                      <div id="VF_vacancies">
                        {!vacancyLoading && (
                          <div className="row VF_vacancies_main_wrap">
                            <div className="col-12">
                              {careersNavState !== "alerts" ? (
                                <div className="main-section">
                                  <div className="row search-container-wrap">
                                    <div className="inner-content grid-section box-theme-2 joblist-section col-xs-12 col-12">
                                      <div
                                        id="VF-vacancies-wrapper"
                                        className="col-12 VF_vacancies_list"
                                      >
                                        <div className="row">
                                          {loading ? (
                                            "Loading vacancies..."
                                          ) : (
                                            <>
                                              {vacancy &&
                                              vacancy !== [] &&
                                              vacancy !== "[]" &&
                                              !initialLoading ? (
                                                <>
                                                  {vacancy &&
                                                  vacancy.length > 0 ? (
                                                    vacancy.map((job, i) => (
                                                      <div
                                                        className="job-card card"
                                                        id={new Buffer(
                                                          job.Id
                                                        ).toString("base64")}
                                                        key={i}
                                                      >
                                                        <Link
                                                          className="job-card-link"
                                                          to={
                                                            "/vacancies/vacancy/" +
                                                            new Buffer(
                                                              job.Id
                                                            ).toString(
                                                              "base64"
                                                            ) +
                                                            "/" +
                                                            slugify(
                                                              job.JobTitle
                                                            )
                                                          }
                                                        >
                                                          <div className="job-card-details">
                                                            <div className="job-card-title">
                                                              {job.JobTitle}
                                                            </div>
                                                            <div className="job-card-location">
                                                              {job.Location}
                                                            </div>
                                                            <div className="job-card-summary">
                                                              {job.Summary}
                                                            </div>
                                                            <div className="job-card-meta">
                                                              <div className="job-card-meta-item">
                                                                <span>
                                                                  Job Type:
                                                                </span>
                                                                {job.JobType}
                                                              </div>
                                                              <div className="job-card-meta-item">
                                                                <span>
                                                                  Salary:
                                                                </span>
                                                                {calculateSalary(
                                                                  job.Currency,
                                                                  job.SalaryFrom,
                                                                  job.SalaryTo,
                                                                  job.SalaryType,
                                                                  job.SalaryPeriod
                                                                )}
                                                              </div>
                                                              <div className="job-card-meta-item">
                                                                <span>
                                                                  Closing Date:
                                                                </span>
                                                                {FormatDateTime(
                                                                  job.ExpiryDate
                                                                )}
                                                              </div>
                                                            </div>
                                                            <div className="job-card-reference">
                                                              {"(Reference: " +
                                                                job.AdvertReference +
                                                                ")"}
                                                            </div>
                                                          </div>
                                                        </Link>
                                                      </div>
                                                    ))
                                                  ) : (
                                                    <div className="job-card card job-card-no-results">
                                                      <div className="job-card-details">
                                                        <div className="no-results">
                                                          Sorry, we don't have
                                                          any vacancies matching
                                                          your search.
                                                        </div>
                                                        <button
                                                          id="reset_vacs"
                                                          className="nav-action-sm"
                                                          onClick={() =>
                                                            resetVacs()
                                                          }
                                                        >
                                                          Reset
                                                        </button>
                                                      </div>
                                                    </div>
                                                  )}
                                                </>
                                              ) : (
                                                <div className="no-vacancies">
                                                  Loading vacancies...
                                                </div>
                                              )}

                                              <div className="load-more-wrap col-12">
                                                {currentCount < vacsCount && (
                                                  <a
                                                    className="nav-action-sm"
                                                    role="button"
                                                    onClick={() => loadMore()}
                                                  >
                                                    {loadMoreText}
                                                  </a>
                                                )}
                                              </div>
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <SmartFeedJobAlerts
                                  apiKey={apiKey}
                                  gdprKey={gdprKey}
                                  group={group}
                                  location={true}
                                  region={true}
                                  category={true}
                                  industry={true}
                                  jobType={true}
                                  jobTime={true}
                                  CompanyGroupName={CompanyGroupName}
                                  VacancyPageBaseURL={VacancyPageBaseURL}
                                  ReturnToSearchURL={ReturnToSearchURL}
                                />
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 column">
                    {" "}
                    <div>
                      <div className="healthspan-spacing">
                        <article aria-label="Subscribe for job alerts">
                          <div className="ctacard soft">
                            <div className="ctacard__body d-flex justify-content-center">
                              <div className="col-md-10 col-lg-6 text-center">
                                <h4 className="ctacard__body__title">
                                  <span>
                                    Can't find a job role to suit you?
                                  </span>
                                </h4>
                                <p className="ctacard__body__summary">
                                  Get job alerts straight to your inbox and be
                                  the first to see our new vacancies.
                                </p>
                                <Link to="/job-alerts/" className="btn--action">
                                  Subscribe for job alerts
                                </Link>
                              </div>
                            </div>
                          </div>
                        </article>
                      </div>{" "}
                    </div>
                  </div>{" "}
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        <div className="page-loading-block"></div>
      )}
    </div>
  )
}

export default SmartFeedFiltersComponent
