import React, { useState } from "react"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"

import SmartFeedFiltersComponent from "../components/SmartFeedWithFilters_WT"

require("es6-promise").polyfill()

export default function VacanciesListPage({ props }) {
  const [vacancyLoading, setVacancyLoading] = useState(true)

  return (
    <Layout {...props}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Jobs at Healthspan</title>
        <meta
          name="description"
          content=""
        />
      </Helmet>

      <SmartFeedFiltersComponent
        setVacancyLoading={setVacancyLoading}
        vacancyLoading={vacancyLoading}
        apiKey={"UAYuqLuj5r7tAvaNW7qSD6CN-xLilM7rkqVED0gmiaY"}
        group={false}
        gdprKey=""
        CompanyGroupName="Healthspan"
        VacancyPageBaseURL="http://healthspancareers.co.uk//vacancies/vacancy/"
        ReturnToSearchURL="http://healthspancareers.co.uk//vacancies/"
        contentDataData={{}}
      />
    </Layout>
  )
}
